//
// alerts.scss
//

.alert-top-border {
    background-color: var(--#{$prefix}secondary-bg);
    border-color: var(--#{$prefix}border-color);
    border-top: 2px solid var(--#{$prefix}border-color);
}

.alert-outline{
    background-color: $white;
}

.alert-light{
    color: var(--#{$prefix}secondary-color);
}

@each $color,
$value in $theme-colors {
    .alert-top-border{
        &.alert-#{$color} {
            border-top-color: $value;
            color: $value;
        }
    }

    .alert-outline{
        &.alert-#{$color} {
            border: 1px solid rgba($value, .8);
            color: $value;
        }
    }
}

.alert-top-border, .alert-outline{
    &.alert-light{
        color: $text-muted;
    }
}