// 
// _demos.scss
// 

// Demo Only
.button-items {
    margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

// search-box
.search-box{
  .form-control{
      border-radius: 10px;
      padding-left: 40px;
  }
  .search-icon{
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      fill:  var(--#{$prefix}secondary-color);
      width: 16px;
      height: 16px;
  }
}


.user-profile-img{
  position: relative;
  
  .overlay-content{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba($primary, 0.8);
      display: flex;
      height: 100%;
      color: rgba($white, 0.6);
      flex-direction: column;
  }
  .profile-img{
      width: 100%;
      height: 250px;
      object-fit: cover;

      @media (max-width:991px) {
          height: 160px;
      }
  }
}

.user-profile-image{
  object-fit: cover;
}

// Modals

.bs-example-modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}


// Icon demo ( Demo only )
.icon-demo-content {
    text-align: center;
    color: var(--#{$prefix}secondary-color);
  
    i, svg{
      display: block;
      width: 50px;
      height: 50px;
      line-height: 46px;
      font-size: 24px;
      margin: 0px auto 16px;
      border: 2px solid var(--#{$prefix}border-color);
      color: var(--#{$prefix}secondary-color);
      fill: var(--#{$prefix}secondary-color);
      border-radius: 1rem;
      transition: all 0.4s;
    }
    svg {
      padding: 10px;
    }
  
    .col-lg-4 {
      margin-top: 24px;
  
      &:hover {
        i, svg {
          color: $white;
          fill: $white;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
}


// Grid

.grid-structure {
    .grid-container {
        background-color: var(--#{$prefix}tertiary-bg);
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $font-weight-medium;
        padding: 10px 20px;
    }
}

// toast
.toast:not(:last-child) {
  margin-bottom: .75rem;
}

// ribbon

.ribbon{
  position: absolute;
  right: -26px;
  top: 20px;
  transform: rotate(45deg);
  color: $white;
  font-size: 13px;
  font-weight: $font-weight-medium;
  padding: 1px 22px;
  font-size: 13px;
  font-weight: 500;
}


// card radio



.card-radio{
  background-color: var(--#{$prefix}secondary-bg);
  border: 2px solid var(--#{$prefix}border-color);
  border-radius: $border-radius;
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  &:hover{
    cursor: pointer;
  }
}

.card-radio-label{
  display: block;
}

.edit-btn{
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  right: 25px;
  margin-top: -50px;
}


.card-radio-input{
  display: none;
  &:checked + .card-radio {
    border-color: $primary !important;

    &:before{
      content: '\e9da';
      font-family: "boxicons";
      position: absolute;
      top: 0px;
      right: 25px;
      font-size: 22px;
      color: $primary;
    }
  }
}

.navs-carousel{
  .owl-nav{
      margin-top: 16px;
      button{
          width: 30px;
          height: 30px;
          line-height: 28px !important;
          font-size: 20px !important;
          border-radius: 50% !important;
          background-color: rgba($primary, 0.25) !important;
          color: $primary !important;
          margin: 4px 8px !important;
      }
  }
}

@media (min-width: 576px){
  .currency-value{
      position: relative;
      &:after{
          content: "\F04E1";
          font-family: "Material Design Icons";
          font-size: 24px;
          position: absolute;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          text-align: center;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: $primary;
          color: $white;
          z-index: 9;
          right: -30px;
      }
  }

}

.crypto-buy-sell-nav-content{
  border: 2px solid var(--#{$prefix}border-color);
  border-top: 0;
}